<template>
  <app-card-code
    title=""
    code=""
    name="detallePoliza"
  >
    <h3 class="ml-5">
      <v-icon
        class="mr-1"
        color="primary"
        @click="volver_poliza"
      >
        {{ icons.mdiArrowLeftCircle }}
      </v-icon>
      Detalle póliza - {{ productoNombre }}
    </h3>
    <v-col cols="12">
      <v-row class="mb-0 mt-3">
        <v-col
          md="12"
          cols="12"
          class="header-inf-basica"
        >
          <h4
            class="header-inf-basica-text"
            style="color:#ffffff !important;"
          >
            Información básica del asegurado
            <!-- <v-icon
              class="ml-15"
              @click="verCliente"
            >
              {{ icons.mdiAccountDetails }}
            </v-icon> -->
          </h4>
        </v-col>

        <v-col
          md="4"
          cols="11"
          class="text-inf"
        >
          <span class="font-weight-medium">Rut :</span>
          <span
            v-mask="maskRut"
            class="text--secondary"
          >{{ maskRut(informacionCliente.rut)[0] }}</span>
        </v-col>
        <v-col
          md="8"
          cols="11"
          class="text-inf"
        >
          <span class="font-weight-medium me-2">Nombres y Apellidos:</span>
          <span class="text--secondary">{{ informacionCliente.nombres }} {{ informacionCliente.primerApellido }}</span>
        </v-col>
        <v-col
          md="4"
          cols="11"
          class="text-inf"
        >
          <span class="font-weight-medium me-2">Fecha Nacimiento :</span>
          <span class="text--secondary">{{ informacionCliente.fechaNacimiento |formatFecha }}</span>
        </v-col>
        <v-col
          md="5"
          cols="11"
          class="text-inf"
        >
          <span class="font-weight-medium me-2">Correo:</span>
          <span class="text--secondary">{{ informacionCliente.correo }}</span>
        </v-col>
        <v-col
          md="3"
          cols="11"
          class="text-inf"
        >
          <span class="font-weight-medium me-2">Teléfono:</span>
          <span class="text--secondary">{{ formatearTelefono(informacionCliente.telefono) }}</span>
        </v-col>

        <v-col
          md="12"
          cols="12"
          class=" mt-5"
        >
          <!-- <v-row justify="space-around"> -->
          <v-card>
            <v-tabs
              v-model="tab"
              background-color="#66aeec"
              show-arrows
              dark
            >
              <v-tab
                v-for="item in items"
                :key="item.tab"
              >
                {{ item.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items
              v-model="tab"
            >
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row class="mb-10 mt-5">
                      <v-col
                        md="12"
                        cols="11"
                      >
                        <v-card
                          class="mx-auto"
                          max-width="1000"
                          outlined
                          :loading="loading"
                        >
                          <v-list-item
                            three-line
                            style="font-size: 15px;"
                          >
                            <v-list-item-content>
                              <div class="text-overline mb-4">
                                <v-icon
                                  color="primary"
                                >
                                  {{ icons.mdiCardAccountDetailsStar }}
                                </v-icon>  Nombre de la póliza: <span class="text-poliza">{{ producto }}</span>
                              </div>
                              <v-col
                                md="6"
                                cols="12"
                              >
                                <span class="font-weight-medium me-2">Fecha de vigencia: </span>
                                <span class="text--secondary">{{ vigenciaDesde }} / {{ vigenciaHasta }} </span>
                              </v-col>
                              <v-col
                                md="6"
                                cols="12"
                              >
                                <span class="font-weight-medium me-2">Precio mensual:</span>
                                <span class="text--secondary">{{ precioMensual }}</span>
                              </v-col>
                              <v-col
                                md="6"
                                cols="12"
                              >
                                <span class="font-weight-medium me-2">Número de Póliza:</span>
                                <span class="text--secondary">{{ poliza }}</span>
                              </v-col>
                              <v-col
                                md="6"
                                cols="11"
                              >
                                <span class="font-weight-medium me-2">Número de Certificado:</span>
                                <span class="text--secondary">{{ certificado }}</span>
                              </v-col>
                              <v-col
                                md="6"
                                cols="11"
                              >
                                <span class="font-weight-medium me-2">Documento:</span>
                                <span class="text--secondary">{{ documento }}</span>
                              </v-col>
                              <v-col
                                md="6"
                                cols="11"
                              >
                                <span class="font-weight-medium me-2">Propuesta:</span>
                                <span class="text--secondary">{{ propuestas }}</span>
                              </v-col>
                              <v-col
                                md="12"
                                cols="11"
                              >
                                <span class="font-weight-medium me-2">Sucursal:</span>
                                <span class="text--secondary">{{ sucursal }}</span>
                              </v-col>
                              <v-col
                                md="12"
                                cols="11"
                              >
                                <span class="font-weight-medium me-2">Promotor de venta:</span>
                                <span class="text--secondary">{{ vendedor }}</span>
                              </v-col>
                            </v-list-item-content>

                            <!-- <v-avatar
                                color="primary"
                                class="v-avatar-light-bg primary--text md-4"
                                size="300"
                                rounded
                              >
                                <v-icon
                                  color="primary"
                                  size="70"
                                >
                                  {{ icons.mdiCardAccountDetailsStar }}
                                </v-icon>
                              </v-avatar> -->
                          </v-list-item>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- fin parte 1 -->
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        md="12"
                        cols="11"
                      >
                        <v-data-table
                          :headers="headers"
                          :items="desserts"
                          :items-per-page="5"
                          class="elevation-1 mt-10"
                          color="indigo darken-5"
                          hide-default-footer
                          :loading="loading"
                        >
                          <template v-slot:no-data>
                            No existen datos para establa
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- fin parte 2 -->
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      :headers="headers1"
                      :items="desserts1"
                      :items-per-page="5"
                      class="elevation-1 mt-10"
                      hide-default-footer
                      :loading="loading"
                    >
                      <template v-slot:no-data>
                        No existen datos para establa
                      </template>
                      <template
                        v-slot:item.tarifa="{ item }"
                        class="textos"
                      >
                        {{ item }}
                        <!-- <span>{{ '100' | VMask(currencyMask) }}</span> -->
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- fin parte 3 -->
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      :headers="headers2"
                      :items="desserts2"
                      :items-per-page="5"
                      class="elevation-1 mt-10"
                      hide-default-footer
                      :loading="loading"
                    >
                      <template v-slot:no-data>
                        No existen datos para establa
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- fin parte 4 -->
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      :headers="headers3"
                      :items="desserts3"
                      :items-per-page="5"
                      class="elevation-1 mt-10"
                      hide-default-footer
                      :loading="loading"
                    >
                      <template
                        v-slot:item.acciones="{ item }"
                        class="textos"
                      >
                        <v-row>
                          <v-dialog
                            v-model="dialog"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="ml-3"
                                color="primary"
                                x-small
                                text
                                title="Documentos"
                                v-bind="attrs"
                                @click="verdocumentos(item)"
                                v-on="on"
                              >
                                <v-icon>{{ icons.mdiBookMultiple }}</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-toolbar
                                dark
                                color="primary"
                              >
                                <v-btn
                                  icon
                                  dark
                                  @click="dialog = false"
                                >
                                  <v-icon>{{ icons.mdiClose }}</v-icon>
                                </v-btn>
                                <v-toolbar-title> {{ tituloDocumento }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                              </v-toolbar>
                              <v-list
                                three-line
                                subheader
                              >
                                <div class="row">
                                  <div class="col-md-12">
                                    <object
                                      class="PDFdoc"
                                      width="100%"
                                      height="800%"
                                      type="application/pdf"
                                      :data="documentoPDF"
                                    ></object>
                                  </div>
                                </div>
                              </v-list>
                            </v-card>
                          </v-dialog>
                        </v-row>
                      </template>
                      <template v-slot:no-data>
                        No existen datos para establa
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- fin parte 5 -->
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      :headers="headers4"
                      :items="desserts4"
                      :items-per-page="5"
                      class="elevation-1 mt-10"
                      hide-default-footer
                      :loading="loading"
                    >
                      <template v-slot:no-data>
                        No existen datos para establa
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- fin parte 5 -->
            </v-tabs-items>
          </v-card>
          <!-- </v-row> -->
        </v-col>
      </v-row>
    </v-col>
    <v-snackbar
      v-model="isFormSubmittedSnackbarVisible"
      right
      top
      :color="colorSnackbar"
    >
      {{ textSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="isFormSubmittedSnackbarVisible = false"
        >
          <v-icon>{{ icons.mdiCloseThick }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </app-card-code>
  <!-- </v-row> -->
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiClose,
  mdiCheck,
  mdiCached,
  mdiMagnify,
  mdiAccount,
  mdiCardAccountDetailsStar,
  mdiBookMultiple,
  mdiAccountDetails,
  mdiArrowLeftCircle,
} from '@mdi/js'

// import VueMask from 'v-mask'
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import { maskRut, cleanMaskRut } from '@/helpers/rut'
import { polizastService } from '../../../../service/poliza.service'

export default {
  components: {
    // DemoFormLayoutTabs,
    AppCardCode,
  },
  setup() {
    const loading = ref(false)
    const rut = ref('')
    const nombres = ref('')
    const fechaNacimiento = ref('')
    const correo = ref('')
    const telefono = ref('')
    const producto = ref('')
    const vigenciaDesde = ref('')
    const vigenciaHasta = ref('')
    const precioMensual = ref('')
    const poliza = ref('')
    const certificado = ref('')
    const documento = ref('')
    const propuestas = ref('')
    const sucursal = ref('')
    const vendedor = ref('')
    const tituloDocumento = ref('')
    const documentoPDF = ref('')

    const dialog = ref(false)
    const informacionCliente = ref({})
    const productoNombre = ref('')
    const isFormSubmittedSnackbarVisible = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')

    return {
      dialog,
      maskRut,
      loading,
      rut,
      nombres,
      fechaNacimiento,
      correo,
      telefono,
      producto,
      vigenciaDesde,
      vigenciaHasta,
      precioMensual,
      poliza,
      certificado,
      documento,
      propuestas,
      sucursal,
      vendedor,
      tituloDocumento,
      documentoPDF,

      // currencyMask,

      tab: null,
      items: [
        { tab: 'Producto', content: 'Tab 1 Content' },
        { tab: 'Cobertura', content: 'Tab 2 Content' },
        { tab: 'Beneficiarios', content: 'Tab 3 Content' },
        { tab: 'Recaudación', content: 'Tab 4 Content' },
        { tab: 'Documentos', content: 'Tab 5 Content' },
        { tab: 'Endoso', content: 'Tab 6 Content' },
      ],
      headers: [
        { text: 'Nombres', value: 'cobe_nombre' },
        { text: '% Impuestos', value: 'impuesto' },
        { text: 'Tarifa', value: 'tarifa' },
        { text: 'Monto', value: 'monto' },
      ],
      desserts: [],
      headers1: [
        { text: 'Nombre completo', value: 'nombre' },
        { text: 'Parentesco', value: 'pare_nombre' },
        { text: '% Participación', value: 'montoParticipacion' },
      ],
      desserts1: [],
      headers2: [
        { text: 'Número de recaudación', value: 'numero_recaudacion' },
        { text: 'Fecha de vencimiento', value: 'fecha_vencimiento' },
        { text: 'Fecha de pago', value: 'fecha_pago' },
        { text: 'Estado', value: 'status' },
      ],
      desserts2: [],
      headers3: [
        { text: 'Tipo de documento', value: 'tipoDocumento' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Tipo firma', value: 'tipoFirma' },
        { text: 'Canal firma', value: 'canalFirma' },
        { text: 'Acciones', value: 'acciones' },
      ],
      desserts3: [],
      headers4: [
        { text: 'Número endoso', value: 'numeroEndoso' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Fecha', value: 'fecha' },
      ],
      desserts4: [],
      icons: {
        mdiClose,
        mdiCheck,
        mdiCached,
        mdiMagnify,
        mdiAccount,
        mdiCardAccountDetailsStar,
        mdiBookMultiple,
        mdiAccountDetails,
        mdiArrowLeftCircle,
      },
      informacionCliente,
      productoNombre,
      isFormSubmittedSnackbarVisible,
      colorSnackbar,
      textSnackbar,
    }
  },
  filters: {
    formatFecha(valor) {
      const dato = valor.split('-')

      return typeof dato === typeof [] ? `${dato[2]}-${dato[1]}-${dato[0]}` : 'Sin información'
    },
  },
  mounted() {
    // obteniendo la informacion del cliente del storage
    this.informacionCliente = JSON.parse(localStorage.getItem('informacionCliente'))
    this.productoNombre = localStorage.getItem('productoPolizaNombre')
    this.rut = this.informacionCliente.rut

    // modificaron el rut en los parametros de la url y no coincide con la data en localStorage
    if (cleanMaskRut(this.$router.currentRoute.params.rut) !== this.informacionCliente.rut) {
      this.volver_poliza()
    }

    this.bucarRut(
      this.$router.currentRoute.params.rut,
      this.$router.currentRoute.params.propuesta,
      this.informacionCliente,
    )
  },
  methods: {
    formatFecha(valor) {
      const dato = valor.split('-')

      return typeof dato === typeof [] ? `${dato[2]}-${dato[1]}-${dato[0]}` : 'Sin información'
    },
    volver_poliza() {
      this.$router.push({
        name: 'poliza_id',
        params: {
          rut: this.rut,
        },
      })
    },
    verCliente() {
      this.$router.push({
        name: 'verCliente',
        params: {
          rut: this.rut,
        },
      })
    },
    async bucarRut(itemRut, propuesta, informacionCliente) {
      console.log('ENTRA EN BUSCAR RUT ', informacionCliente)
      this.loading = true
      this.respuestaRut = false
      this.stipeActive = true
      const rutFormat = cleanMaskRut(itemRut)
      console.log('BUSCANDO DETALLES DE LA POLIZA ', informacionCliente)
      await polizastService
        .getPolizaDetalle(rutFormat, propuesta)
        .then(resp => {
          console.log(resp)
          if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
            this.loading = false

            // const informacion = resp.data.data.informacionCliente
            const product = resp.data.data.producto
            const departamento = resp.data.data.informacionDepartamento

            // Informacion producto
            this.producto = product.producto
            this.productoNombre = product.producto
            this.vigenciaDesde = this.formatFecha(product.vigenciDesde)
            this.vigenciaHasta = this.formatFecha(product.vigenciaHasta)
            this.precioMensual = `${new Intl.NumberFormat('de-DE').format(product.precio)} ${product.moneda.simbolo}`
            this.poliza = product.poliza
            this.certificado = product.certificado
            this.documento = product.documento
            this.propuestas = product.propuesta

            // Punto de venta
            this.sucursal = departamento.sucursal.nombre
            this.vendedor = `${departamento.vendedor.nombres} ${departamento.vendedor.apellidos}`

            // coberturas
            this.desserts = resp.data.data.coberturas.map(e => ({
              cobe_nombre: e.cobe_nombre,
              id: e.id,
              impuesto: e.impuesto,
              monto: new Intl.NumberFormat('de-DE').format(e.monto),
              tarifa: new Intl.NumberFormat('de-DE').format(e.tarifa),
            }))
            this.desserts1 = resp.data.data.beneficiarios
            this.desserts3 = resp.data.data.documentos
            this.desserts4 = resp.data.data.endosos.map(e => ({
              id: e.id,
              fecha: this.formatFecha(e.fecha),
              numeroEndoso: e.numeroEndoso,
              tipo: e.tipo,
            }))
          } else if (resp.data.statusCode === 400) {
            console.log(resp.data.message)
            this.isFormSubmittedSnackbarVisible = true
            this.textSnackbar = resp.data.message
            this.colorSnackbar = 'error'
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    verdocumentos(item) {
      const decodedStr = `data:application/pdf;base64,${item.documento}`
      this.documentoPDF = decodedStr
      this.tituloDocumento = item.tipoDocumento
    },
    formatearTelefono(telefono) {
      return `(${telefono.substr(0, 3)}) - ${telefono.substr(3, 3)}-${telefono.substr(6, 4)}`
    },
  },
}
</script>
<style scoped>
.header-inf-basica {
  background: linear-gradient(98deg, #67afed, var(--v-primary-base) 94%);
  color: white !important;
  font-weight: 300 !important;
  text-align: center;
  border-radius: 10px;
}
.header-inf-basica-text {
  color: white !important;
  font-weight: 300 !important;
}
.text-poliza {
  color: #156bb6 !important;
}
.text-inf {
  font-size: 14px;
}
</style>
